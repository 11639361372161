.top-bar {
  background: white;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  filter: drop-shadow(0px 10px 16px rgba($raisin-black, 5%));

  .top-bar-content {
    display: flex;
    align-items: center;
    height: 80px;

    strong {
      position: relative;
      background: $medium-slate-blue;
      border-radius: 2px 2px 2px 0;
      color: white;
      padding: 4px 8px;
      text-transform: uppercase;
      font-weight: 800;
      font-size: 15px;
      cursor: pointer;

      &:after {
        content: '';
        border-left: 8px solid $medium-slate-blue;
        border-bottom: 8px solid transparent;
        position: absolute;
        bottom: -8px;
        left: 0;
      }
    }

    span {
      font-weight: 600;
      margin-right: auto;
      margin-left: 30px;
      color: $raisin-black;
    }
  }

  .no-user {
    span {
      color: rgba($raisin-black, 30%);
    }
  }
}

@media (max-width: 540px) {
  .top-bar {
    .top-bar-content {
      strong {
        font-size: 10px;
      }
      span {
        font-size: 14px;
        margin-left: 10px;
      }
      &.no-user {
        span {
          display: none;
        }
        button {
          margin-left: auto;
        }
      }
    }
  }
}
