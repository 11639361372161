.sign-in-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba($raisin-black, 30%);

  .sign-in-modal-container {
    position: relative;
    background: white;
    padding: 60px;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
  }

  h3 {
    margin-top: 0;
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
  }

  .back {
    position: absolute;
    top: 0;
    left: 0;
  }

  form {
    display: flex;
    flex-direction: column;
    min-width: 300px;
  }

  label {
    font-size: 12px;
    color: rgba($raisin-black, 40%);
  }

  input {
    background: rgba($cultured, 70%);
    border: none;
    border-radius: 2px;
    padding: 14px;
    margin: 8px 0 16px;
    font-size: 15px;
    &:focus {
      outline: none;
      box-shadow: 0 0 0 1px $majorelle-blue;
    }
  }

  button {
    text-align: center;
  }

  i {
    color: rgba($raisin-black, 40%);
  }

  .btn-blue-outline {
    width: 100%;
    margin-top: 10px;
  }
}

@media (max-width: 540px) {
  .sign-in-modal {
    justify-content: stretch;
    padding: 20px;

    .sign-in-modal-container {
      padding: 60px 40px 40px;
      flex: 1;
    }

    form {
      min-width: unset;
      flex: 1;
    }
  }
}
