.chat {
  display: flex;
  flex-direction: column;
  padding: 130px 0 120px;

  .chat-info {
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1;
    background: rgba($winter-sky, 10%);
    backdrop-filter: blur(6px);

    .chat-info-container {
      padding: 10px 20px;
    }

    .chat-name-container {
      color: $winter-sky;
      font-size: 12px;
    }

    .chat-name {
      font-weight: 700;
    }
  }

  .chat-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .chat-no-messages {
    text-align: center;
    padding-top: 160px;
    h2 {
      color: rgba($raisin-black, 20%);
    }
  }

  .chat-input {
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0px 10px 16px rgba($raisin-black, 10%);
    background: white;
    padding: 0 20px;
    border-radius: 6px;

    textarea {
      border: none;
      resize: none;
      width: 400px;
      font-size: 15px;
      padding: 20px 0;
      margin-right: 10px;
      line-height: 1.5;

      &:focus {
        outline: none;
      }
    }
  }
}

@media (max-width: 540px) {
  .chat {
    padding: 130px 0 100px;
    .chat-input {
      left: 0;
      right: 0;
      bottom: 0;
      transform: unset;
    }
  }
}
