.btn {
  border: none;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

.btn-small {
  padding: 10px 26px;
  border-radius: 2px;
}

.btn-large {
  padding: 14px 30px;
  border-radius: 2px;
}

.btn-round {
  border-radius: 20px 20px 20px 2px;
}

.btn-blue {
  background: $majorelle-blue;
  color: white;
}

.btn-pink {
  background: $winter-sky;
  color: white;
}

.btn-blue-outline {
  border: 1px solid $majorelle-blue;
  color: $majorelle-blue;
  background: none;
}

.btn-blue-text {
  color: $majorelle-blue;
  background: transparent;
}

.btn-pink-text {
  color: $winter-sky;
  background: transparent;
}

.btn-underline {
  &:hover {
    text-decoration: underline;
  }
}

.btn-icon {
  background: transparent;
  padding: 20px;
}

@media (max-width: 540px) {
  .btn-small {
    font-size: 12px;
  }
}
