body {
  margin: 0;
  background: $cultured;
}

* {
  font-family: usual, sans-serif;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

.max-width {
  max-width: 1020px;
  margin: 0 auto;
  padding: 0 20px;
}

.main {
  padding-top: 260px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    font-size: 20px;
    font-weight: 600;
    color: $raisin-black;
  }

  h2 {
    color: rgba($raisin-black, 100%);
    margin: 0;
    text-align: center;
  }

  button {
    margin-top: 20px;
  }

  form {
    display: flex;
    margin-top: 20px;

    input {
      padding: 14px;
      border: none;
      font-size: 18px;
      margin-right: 10px;
      border-radius: 6px;
      font-weight: 600;
      box-shadow: 0px 10px 16px rgba($raisin-black, 10%);

      &:focus {
        outline: none;
      }
    }
    button {
      margin: 0;
      box-shadow: 0px 10px 16px rgba($raisin-black, 10%);
    }

    @media (max-width: 540px) {
      flex-direction: column;

      input {
        margin: 0 0 10px 0;
      }
    }
  }
}
