.chat-bubble {
  position: relative;
  background: rgba(white, 75%);
  padding: 20px;
  margin-bottom: 10px;
  max-width: 600px;
  width: 80%;
  border-radius: 4px;

  div {
    display: flex;
    align-items: center;
    margin: 0 0 10px;
  }

  .chat-bubble-author {
    font-size: 12px;
    font-weight: 600;
    color: $winter-sky;
  }

  i {
    font-size: 5px;
    margin: 0 10px;
    color: rgba($winter-sky, 50%);
  }

  .chat-bubble-timestamp {
    font-size: 10px;
  }

  .chat-bubble-message {
    margin: 10px 0 0;
    font-size: 14px;
    line-height: 1.5;
  }

  &.chat-bubble-current {
    margin-left: auto;
    background: rgba($medium-slate-blue, 15%);

    .chat-bubble-author {
      color: $majorelle-blue;
    }

    i {
      color: rgba($majorelle-blue, 50%);
    }
  }
}

@media (max-width: 540px) {
  .chat-bubble {
    max-width: 80%;
    .chat-bubble-message {
      font-size: 14px;
    }
  }
}

@media (max-width: 400px) {
  .chat-bubble {
    max-width: 90%;
  }
}
